import { template as template_f7bab334e3284c3caf026002111d4415 } from "@ember/template-compiler";
const FKLabel = template_f7bab334e3284c3caf026002111d4415(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
